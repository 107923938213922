import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../Theme";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCallServer from "../../hooks/useCallServer";
import { toast } from "react-toastify";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MyModal from "../../components/modal/MyModal";

const Licenses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [visible, setVisible] = useState(-1);
  const navigate = useNavigate();
  const { loading, runCall } = useCallServer();
  const [rowData, setRowData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    //fetchData
    const { data, error } = await runCall({
      url: "/licenses/getAll",
      method: "GET",
    });
    if (data) setRowData(data);
    else toast.error(error);
  };

  const deleteLicense = async (licenseId) => {
    const { data, error } = await runCall({
      url: `/licenses/delete/${licenseId}`,
      method: "delete",
    });
    if (data) {
      toast.success(data.message);
      setOpenDelete(false);
      await fetchData();
    } else {
      toast.error(error);
    }
  };

  const sendRenewalReminder = async (licenseId) => {
    const { data, error } = await runCall({
      url: `/users/sendRenewal/${licenseId}`,
      method: "GET",
    });
    if (data) {
      toast.success(data.message);
    } else {
      toast.error(error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "user",
      headerName: "User",
      flex: 2,
      renderCell: ({ row: { user } }) => {
        return user.username;
      },
    },
    {
      field: "key",
      headername: "License",
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Box display={"flex"} alignItems={"center"}>
            {visible === row.id && <Typography>{row.key}</Typography>}
            <IconButton
              title={"View License Key"}
              onClick={() =>
                visible === row.id ? setVisible(-1) : setVisible(row.id)
              }
              style={{ cursor: "pointer" }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "active",
      headerName: "Active",
      renderCell: ({ row: { active } }) => {
        return active ? (
          <CheckCircleOutlineIcon style={{ color: colors.greenAccent[400] }} />
        ) : (
          <CloseIcon style={{ color: colors.redAccent[400] }} />
        );
      },
    },
    {
      field: "expiresOn",
      headerName: "Expiry Date",
      flex: 1,
      renderCell: ({ row: { expiresOn } }) => {
        const date = new Date(parseInt(expiresOn));
        return date.toDateString();
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box display={"flex"} width={"100%"}>
            <IconButton
              style={{ cursor: "pointer" }}
              title={"Send Renewal Reminder"}
              onClick={() => sendRenewalReminder(row.id)}
              sx={{ cursor: "pointer" }}
            >
              <EmailOutlinedIcon />
            </IconButton>
            <MyModal
              onCancel={() => setOpenDelete(false)}
              onConfirm={() => deleteLicense(row.id)}
              open={openDelete}
              confirmColor={"error"}
              cancelColor={"info"}
              component={
                <IconButton
                  style={{ cursor: "pointer", color: colors.redAccent[400] }}
                  title={"Delete License"}
                  onClick={() => setOpenDelete(true)}
                  sx={{ cursor: "pointer" }}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              }
              title={`Delete license for ${row.user.username}?`}
            >
              <Typography>
                This will cause their license to be revoked.
              </Typography>
            </MyModal>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title={"Licenses"} subTitle={"Manage Licenses"} />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate("/licenseEdit")}
      >
        Add New
      </Button>
      <Box
        m="40px 0 0 0"
        height={"70vh"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid rows={rowData} columns={columns} />
      </Box>
    </Box>
  );
};

export default Licenses;
