import { Button } from "@mui/material";
import { ReactComponent as Loader } from "./spinner.svg";
import "./index.css";

const LoadingButton = ({
  className,
  type,
  variant,
  color,
  onClick,
  onSubmit,
  text,
  loading,
}) => {
  return (
    <Button
      className={`${className}`}
      sx={{
        "& :disabled": {
          cursor: "not-allowed",
        },
      }}
      onClick={onClick}
      type={type}
      onSubmit={onSubmit}
      variant={variant}
      color={color}
      disabled={loading}
    >
      {!loading ? text : <Loader />}
    </Button>
  );
};

export default LoadingButton;
