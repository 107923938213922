import { Box, Button, IconButton, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useState } from "react";
import useCallServer from "../../hooks/useCallServer";
import { toast } from "react-toastify";

const initialValues = {
  id: "",
  userId: "",
  daysActive: "",
};

const phoneRegEx =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const userSchema = yup.object().shape({
  userId: yup.number().required("This field is required"),
  daysActive: yup.number().required("This field is required"),
  id: yup.number(),
});

const LicenseForm = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const { loading, runCall } = useCallServer();

  const handleFormSubmit = async (values) => {
    const { data, error } = await runCall({
      url: "/licenses/create",
      body: values,
      method: "POST",
    });
    if (error) {
      toast.error(error);
    } else {
      toast.success(data.message);
    }
  };

  return (
    <Box m="20px">
      <IconButton title="Go Back" onClick={() => navigate("/licenses")}>
        <ArrowBackOutlinedIcon />
      </IconButton>
      <Header title={"License"} subTitle={"Save License Details"} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={userSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={"repeat(4, minmax(0, 1fr))"}
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="number"
                label="Id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
                name="id"
                error={!!touched && !!errors.id}
                helperText={touched.id && errors.id}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="User"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userId}
                name="userId"
                error={!!touched && !!errors.userId}
                helperText={touched.userId && errors.userId}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Days Active"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.daysActive}
                name="daysActive"
                error={!!touched && !!errors.daysActive}
                helperText={touched.daysActive && errors.daysActive}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display={"flex"} justifyContent={"end"} mt="20px">
              <Button variant="contained" color="secondary" type="submit">
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LicenseForm;
