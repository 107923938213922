import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../Theme";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Header from "../../components/Header";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import useCallServer from "../../hooks/useCallServer";
import { toast } from "react-toastify";
import MyModal from "../../components/modal/MyModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const timeframes = [
  { key: "days", value: "DAYS" },
  { key: "weeks", value: "WEEKS" },
  { key: "months", value: "MONTHS" },
  { key: "years", value: "YEARS" },
];

const Licenses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [visible, setVisible] = useState(-1);
  const navigate = useNavigate();
  const { loading, runCall } = useCallServer();
  const [rowData, setRowData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);
  const periodRef = useRef();
  const timeframeRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data, error } = await runCall({
      url: "/admins/getAll",
      method: "GET",
    });
    if (data) setRowData(data);
    else toast.error(error);
  };

  const deleteUser = async (userId) => {
    const { data, error } = await runCall({
      url: `/admins/delete/${userId}`,
      method: "delete",
    });
    if (data) {
      toast.success(data.message);
      setOpenDelete(false);
      await fetchData();
    } else {
      toast.error(error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "username",
      headername: "Username",
      flex: 2,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box display={"flex"}>
            <IconButton
              style={{ cursor: "pointer" }}
              title={"Change Admin Details"}
              onClick={() => navigate("/adminEdit", { state: row })}
            >
              <EditOutlinedIcon />
            </IconButton>
            <MyModal
              onCancel={() => setOpenDelete(false)}
              onConfirm={() => deleteUser(row.id)}
              open={openDelete}
              confirmColor={"error"}
              cancelColor={"info"}
              component={
                <IconButton
                  style={{ cursor: "pointer", color: colors.redAccent[400] }}
                  title={"Delete User Account"}
                  onClick={() => setOpenDelete(true)}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              }
              title={`Delete ${row.username}?`}
            >
              <Typography>
                This will cause their permissions to be revoked from the system
              </Typography>
            </MyModal>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title={"Users"} subTitle={"Manage Software End Users"} />
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={() => navigate("/adminEdit")}
        text={"Add New"}
      />
      <Box
        m="40px 0 0 0"
        height={"70vh"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid rows={rowData} columns={columns} />
      </Box>
    </Box>
  );
};

export default Licenses;
