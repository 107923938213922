import { useState } from "react";
import axios from "axios";

const useCallServer = () => {
  const [loading, setLoading] = useState(false);

  const runCall = async ({
    url,
    method = "GET",
    params = null,
    body = null,
  }) => {
    setLoading(true);
    try {
      const response = await axios({
        url: `http://imports.gpridetracking.com:9000/api${url}`,
        method: method,
        data: body,
        params: params,
        headers: {},
        withCredentials: true,
      });
      const data = response.data;
      setLoading(false);
      if (data.successful) {
        return {
          data: data.data,
          error: null,
        };
      } else {
        return {
          data: null,
          error: data.data.message,
        };
      }
    } catch (error) {
      setLoading(false);
      return {
        data: null,
        error: error.message,
      };
    }
  };

  return { loading, runCall };
};

export default useCallServer;
