import { colorModeContext, useMode } from "./Theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Topbar from "./global/Topbar";
import MySidebar from "./global/Sidebar";
import Dashboard from "./pages/dashboard";
import Licenses from "./pages/licenses";
import Users from "./pages/users";
import UserForm from "./pages/users/UserForm";
import LicenseForm from "./pages/licenses/LicenseForm";
import Admins from "./pages/admins";
import AdminForm from "./pages/admins/AdminForm";
// import Profile from "./pages/profile";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <colorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <MySidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/licenses" element={<Licenses />} />
              <Route path="/users" element={<Users />} />
              <Route path="/userEdit" element={<UserForm />} />
              <Route path="/licenseEdit" element={<LicenseForm />} />
              <Route path="/admins" element={<Admins />} />
              <Route path="/adminEdit" element={<AdminForm />} />
              {/* <Route path="/profile" element={<Profile />} /> */}
            </Routes>
          </main>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={theme.palette.mode}
          />
        </div>
      </ThemeProvider>
    </colorModeContext.Provider>
  );
}

export default App;
