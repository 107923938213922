import {
  Box,
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import useCallServer from "../../hooks/useCallServer";
import { toast } from "react-toastify";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { useEffect, useState } from "react";

const phoneRegEx =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const userSchema = yup.object().shape({
  username: yup.string().required("This field is required"),
  email: yup
    .string()
    .email("Please provide a valid email")
    .required("This field is required"),
  phone: yup
    .string()
    .matches(phoneRegEx, "Please provide a valid phone number")
    .required("This field is required"),
  password: yup.string(),
  id: yup.number(),
});

const generatePassword = () => {
  let charset = "";
  let newPassword = "";

  charset += "!@#$%^&*()";
  charset += "0123456789";
  charset += "abcdefghijklmnopqrstuvwxyz";
  charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < 8; i++) {
    newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return newPassword;
};

const AdminForm = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const { loading, runCall } = useCallServer();
  const { state } = useLocation();
  const [initialValues, setInitialValues] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    id: "",
  });

  useEffect(() => {
    if (state) {
      setInitialValues({
        username: state.username,
        email: state.email,
        phone: state.phone,
        password: "",
        id: state.id,
      });
    }
  }, [state]);

  const handleFormSubmit = async (values) => {
    const { data, error } = await runCall({
      url: values.id === "" ? "/admins/create" : "/admins/update",
      body: {
        ...values,
        password:
          values.id === "" && values.password === ""
            ? generatePassword()
            : values.password,
      },
      method: values.id ? "PUT" : "POST",
    });
    if (error) {
      toast.error(error);
    } else {
      toast.success(data.message);
      if (values.id !== "") {
        navigate(-1);
      }
    }
  };

  return (
    <Box m="20px">
      <IconButton title="Go Back" onClick={() => navigate("/admins")}>
        <ArrowBackOutlinedIcon />
      </IconButton>
      <Header title={"Admin"} subTitle={"Save Admin Details"} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={userSchema}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={"repeat(4, minmax(0, 1fr))"}
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="number"
                label="Id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
                name="id"
                error={!!touched && !!errors.id}
                helperText={touched.id && errors.id}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="UserName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl sx={{ gridColumn: "span 4" }} variant="filled">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <FilledInput
                  fullWidth
                  id="outlined-adornment-password"
                  type="text"
                  error={!!touched && !!errors.password}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setFieldValue("password", generatePassword())
                        }
                        edge="end"
                        title="Generate new Password"
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Box>
            <Box display={"flex"} justifyContent={"end"} mt="20px">
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                text={"Save"}
                loading={loading}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AdminForm;
