import {
  Box,
  Button,
  ButtonBase,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { tokens } from "../../Theme";

const MyModal = ({
  component,
  title,
  children,
  open,
  onConfirm,
  onCancel,
  confirmColor = "success",
  cancelColor = "error",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    } else toast.warn("No confirm action");
  };

  return (
    <Box>
      {component}
      <Modal open={open} onClose={handleCancel}>
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
              height: "40%",
              justifyContent: "space-around",
              alignItems: "flex-start",
              background: colors.primary[400],
              p: "20px",
              borderRadius: "5%",
            }}
          >
            <Typography variant="h3" fontWeight={"bold"} sx={{ mb: "20px" }}>
              {title}
            </Typography>
            {children}
            <Box
              display={"flex"}
              justifyContent={"space-evenly"}
              alignItems={"center"}
              width={"100%"}
            >
              <Button
                variant="contained"
                color={confirmColor}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color={cancelColor}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MyModal;
